<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="editApi.isError"
      :api="editApi"
      :callbackReset="() => (editApi.isError = false)"
    ></AError>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-row>
        <v-col cols="12" sm="6">
          <h2>Edit User</h2>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex justify-center justify-sm-end">
            <div>
              <v-btn
                rounded
                color="error"
                outlined
                :to="{
                  name: 'PageEducatorUserDelete',
                  params: { id: this.$route.params.id },
                }"
              >
                Delete
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-card-title></v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <TextField
                :value="name"
                label="Full name"
                :rules="nameRules"
                type="text"
                @input="name = $event"
              ></TextField>
            </div>
            <div class="text-center pt-3 d-none">
              <SelectField
                :value="selectedType"
                :field="selectField"
                @input="selectedType = $event"
              ></SelectField>
            </div>
            <div v-if="selectedType == 'child'" class="text-center pt-3">
              <SelectField
                :value="schoolYear"
                :field="selectSchoolYearField"
                @input="schoolYear = $event"
                label="School Year"
              ></SelectField>
            </div>
            <div class="text-left text-caption">
              <div>First Day of School This Year</div>
            </div>
            <div v-if="selectedType == 'child'" class="d-flex">
              <div class="text-center pt-3">
                <SelectField
                  :value="month"
                  :field="selectMonthField"
                  @input="month = $event"
                  label="Month"
                ></SelectField>
              </div>
              <div class="text-center pt-3 pl-3">
                <SelectField
                  :value="year"
                  :field="selectYearField"
                  @input="year = $event"
                  label="Year"
                ></SelectField>
              </div>
            </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                :loading="editApi.isLoading"
                rounded
                color="primary"
                dark
                @click="submit()"
              >
                Submit
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="$router.go(-1)"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import SelectField from "../../components/Form/SelectField.vue";
import TextField from "../../components/Form/TextField.vue";
import datasetUser from "@/datasets/userType";
import datasetSchoolYears from "@/datasets/schoolYear";
import datasetMonths from "@/datasets/month";
import datasetYears from "@/datasets/year";
import moment from "moment";
export default {
  components: {
    SelectField,
    TextField,
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    selectField: {
      defaultValue: "",
      name: "User type",
      options: datasetUser,
      optionText: "name",
      optionValue: "value",
    },
    valid: true,
    name: null,
    nameRules: [],
    id: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    editApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    selectedType: null,
    selectSchoolYearField: {
      defaultValue: 1,
      name: "School Year",
      options: datasetSchoolYears,
      optionText: "name",
      optionValue: "id",
    },
    schoolYear: 1,
    selectMonthField: {
      defaultValue: 3,
      name: "Month",
      options: datasetMonths,
      optionText: "name",
      optionValue: "id",
    },
    month: 3,

    selectYearField: {
      defaultValue: parseInt(moment().format('YYYY')),
      name: "Year",
      options: datasetYears,
      optionText: "",
      optionValue: "",
    },
    year:parseInt(moment().format('YYYY')),
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: "Dashboard",
      to: { name: "PageEducatorDashboard" },
      exact: true,
    });
    this.breadcrumbs.push({
      text: "User",
      to: {
        name: "PageEducatorUserRead",
        params: { id: this.$route.params.id },
      },
      exact: true,
    });
    this.breadcrumbs.push({
      text: "Edit",
      to: {
        name: "PageEducatorUserEdit",
        params: { id: this.$route.params.id },
      },
      exact: true,
    });
    //EOC
    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/backend/user/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.name = resp.name;
      this.selectedType = resp.type;
      if (resp.year) {
        this.year = resp.year;
        this.schoolYear = resp.schoolYear;
        this.month = resp.month;
      }
      // this.updateBreadcrumb(resp);
    };

    //EOC
    this.editApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/backend/user/edit";

    this.editApi.callbackReset = () => {
      this.editApi.isLoading = true;
      this.editApi.isError = false;
    };

    this.editApi.callbackError = (e) => {
      this.editApi.isLoading = false;
      this.editApi.isError = true;
      this.editApi.error = e;
    };

    this.editApi.callbackSuccess = () => {
      this.editApi.isLoading = false;
      this.$router.push({ name: "PageEducatorUserRead",params: { id: this.$route.params.id }, });
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.$api.fetch(this.api);
  },
  watch: {
    name: function () {
      this.nameRules = [];
    },
  },
  methods: {
    close() {
      this.$router.push({ name: "PageEducatorUserRead",params: { id: this.$route.params.id }, });
    },
    // updateBreadcrumb(resp) {
    //   this.breadcrumbs.push({
    //     text: "Dashboard",
    //     to: { name: "PageEducatorDashboard" },
    //     exact: true,
    //   });

    //   this.breadcrumbs.push({
    //     text: resp.name,
    //     to: {
    //       name: "PageEducatorUserRead",
    //       params: { id: this.$route.params.id },
    //     },
    //     exact: true,
    //   });

    //   this.breadcrumbs.push({
    //     text: "Edit",
    //     to: { name: "PageEducatorUserEdit" },
    //     exact: true,
    //   });
    // },

    submit() {
      this.nameRules = [(v) => !!v || "Name is required"];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formName.validate()) {
          self.editApi.params = {
            id: self.id,
            name: self.name,
            month: self.selectedType == "child" ? self.month : null,
            schoolYear: self.selectedType == "child" ? self.schoolYear : null,
            year: self.selectedType == "child" ? self.year : null,
            type: self.selectedType,
          };
          self.$api.fetch(self.editApi);
        } else {
          self.editApi.callbackError("Please check the form.");
        }
      });
    },
    selectUserType(item) {
      this.selectedType = item.value;
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>